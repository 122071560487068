import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
} from '@wk/components-react16';
import { usePostDownloadLogMutation } from 'api/orchestrations/orchestrationApi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal } from 'redux/modal/ModalSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/close-modal-info.interface';
import { downloadLog } from 'utils/commonFunctions/CommonFunctions';

const CancelDownloadFooter = () => {
  const dispatch = useAppDispatch();
  const [downloadHook, { isLoading }] = usePostDownloadLogMutation();
  const modalInfo = useAppSelector((state) => state.modal);

  const handleUserRequest = (action: MODAL_ACTIONS) => {
    if (action === MODAL_ACTIONS.cancel) {
      dispatch(
        closeModal({
          id: modalInfo.id,
          action,
        })
      );
    }
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => handleUserRequest(MODAL_ACTIONS.cancel)}
              data-testid="CancelButton"
              disabled={isLoading}
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'ghost'}>
            <button
              type="button"
              data-testid="DownloadButton"
              id="download"
              onClick={() =>
                downloadLog(modalInfo.componentProps['data'], downloadHook)
              }
              disabled={isLoading}
            >
              Download error log
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};

export default CancelDownloadFooter;
